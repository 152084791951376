<template>
<div></div>
</template>

<script setup>
import { post } from '@/utils/request';
import { ref } from 'vue';
import wx from 'weixin-js-sdk'

const isWXBrowser = (() => {
    const ua = window.navigator.userAgent.toLowerCase()
    return /(micromessenger|webbrowser)/g.test(ua)
})
isWXBrowser()
const isWechat = () => {
  return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
}

const APPID = 'wx71c607bafeb19bdd';
const code = ref('')
 // 获取 openid 和 access_token
const openid = ref('')
const longitude = ref('')
const latitude = ref('')

const getCurUserCode = () => {
    // 进入
    if (sessionStorage.getItem("openid")&&sessionStorage.getItem("openid")!="undefined") {
   return false;
  }
  if (isWechat) {
  var code1 = getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
  // var local = window.location.href;
  if (code1 == null || code1 === '') {
   window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + APPID + '&redirect_uri=' + encodeURIComponent(window.location.href) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect'
  } else {
  //  getOpenId(code) //把code传给后台获取用户信息
    code.value = code1
    useOpenID()
    wx.ready(() => {
        console.log('jinru ready')
        wx.getLocation({
            type: 'wgs84',
            success(res) {
                console.log('请求success' + res)
                longitude.value = res.longitude
                latitude.value = res.latitude
                postTeahcerAddress()
            }
        })
    })
  }
  }
 }

 const getUrlParam = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
 }


 const useOpenID = async () => {
    
    const result = await post('/PostReturnOpenID', {
        code: code.value
    })
    openid.value = result.data.UserData.openid
    getWxConfig()
}

const getWxConfig = async () => {
    const result = await post('/PostGetWXconfig', {
        url: window.location.href.split('#')[0],
    })
    wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
        appId: APPID, // 必填，公众号的唯一标识
        timestamp: result.data.UserData.timestamp, // 必填，生成签名的时间戳
        nonceStr: result.data.UserData.noncestr, // 必填，生成签名的随机串
        signature: result.data.UserData.signature,// 必填，签名
        jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
    })
}

const postTeahcerAddress = async () => {
    // eslint-disable-next-line no-unused-vars
    const result = await post('/PostUpdateTeacherAddressInfo', {
                    OpenID: openid.value,
                    AddressInfo: longitude.value+','+latitude.value
                    })
    if (result.data.Status === 0) {
        alert(result.data.Message)
    }
}
getCurUserCode()




 

 
</script>