<template>
  <div class="wrapper">
    <div class="wrapper__input">
      <input class="wrapper__input__content" v-model="teachername" placeholder="请输入姓名"/>
    </div>
    <div class="wrapper__input">
      <input class="wrapper__input__content" v-model="teacherphone" placeholder="请输入手机号" />
    </div>
    <div class="wrapper__login" @click="postTeacherInfo">注册</div>
  </div>
</template>

<script setup>
import { post } from '@/utils/request';
import { ref } from 'vue';

const APPID = 'wx71c607bafeb19bdd';

// const getALocation = () => {
//   wx.config()
// }


const getCurUserCode = () => {
  if (sessionStorage.getItem("openid")&&sessionStorage.getItem("openid")!="undefined") {
   return false;
  }
  if (isWechat) {
  var code1 = getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
  // var local = window.location.href;
  if (code1 == null || code1 === '') {
   window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + APPID + '&redirect_uri=' + encodeURIComponent(window.location.href) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect'
  } else {
  //  getOpenId(code) //把code传给后台获取用户信息
    code.value = code1
  }
  }
 }

 const getUrlParam=  (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
 }


const useTeacherInfo = () => {
  const teachername = ref('')
  const teacherphone = ref('')

  const postTeacherInfo = () => {
    console.log(123)
    post('/PostTeacherRegist', {
    teachername: teachername.value,
    teacherphone: teacherphone.value,
    code: code.value
    }).then(() => {
      alert('注册成功！')
    })
  }
  
  return {teachername, teacherphone, postTeacherInfo}
}
const {teachername, teacherphone, postTeacherInfo} = useTeacherInfo()
const code = ref('')
const isWXBrowser = (() => {

const ua = window.navigator.userAgent.toLowerCase()

return /(micromessenger|webbrowser)/g.test(ua)

})
isWXBrowser()

const isWechat = () => {
  return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
}

getCurUserCode()
</script>

<style lang="scss" scoped>
  .wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    &__input {
      background: #f9f9f9;
      padding: 0 .16rem;
      border: .01rem solid rgba(0,0,0,0.10);
      border-radius: .06rem;
      height: .48rem;
      width: 2.95rem;
      margin: 0 .4rem .16rem .4rem;
      &__content {
        background: none;
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        font-size: .16rem;
        color: #333;
        &::placeholder {
          color: #333;
        }
      }
    }
    &__login {
      margin: .32rem .4rem .16rem .4rem;
      background: #0091ff;
      box-shadow: 0 .04rem .08rem 0 rgba(0, 145, 255, 0.32);
      border-radius: .04rem;
      color: #fff;
      line-height: .48rem;
      font-size: .16rem;
      text-align: center;
    }
  }
</style>
